module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/Users/bruno/Documents/GitHub/ROKPROD/src/layouts/layout.js"},"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"/Users/bruno/Documents/GitHub/ROKPROD/node_modules/gatsby-remark-images","id":"4713f087-2dc2-58b4-adfd-9e8e628afa05","name":"gatsby-remark-images","version":"6.5.1","modulePath":"/Users/bruno/Documents/GitHub/ROKPROD/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1500,"quality":60},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/bruno/Documents/GitHub/ROKPROD","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1500,"quality":60},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ROK FISHING","short_name":"ROK","description":"ROK Fishing Performance","start_url":"/","lang":"fr","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"./static/favicon-rok.png","icon_options":{"purpose":"any maskable"},"localize":[{"start_url":"/en/","lang":"en","name":"ROK FISHING","short_name":"ROK","description":"ROK Fishing Performance"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6a8ab49c2f37165b9e99651ba13a0812"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
